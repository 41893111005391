const validateEmail = value => {
  let error;
  if (!value) {
    error = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(value)) {
    error = 'Email address is invalid';
  }

  return error;
};

const validateFname = value => {
  let error;
  if (!value) {
    error = 'First name is required';
  }

  return error;
};

const validateLname = value => {
  let error;
  if (!value) {
    error = 'Last name is required';
  } else {
    error = false;
  }

  return error;
};

const validatePhone = value => {
  let error;
  if (!value) {
    error = 'Phone number is required';
  } else if (
    !/^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[-. \\/]?)?((?:\(?\d{1,}\)?[-. \\/]?){4,})(?:[-. \\/]?(?:#|ext\.?|extension|x)[-. \\/]?(\d+))?$/.test(
      value,
    )
  ) {
    error = 'Phone number is invalid';
  }

  return error;
};

const validateCompany = value => {
  let error;
  if (!value) {
    error = 'Company name is required';
  }

  return error;
};

export {
  validateEmail,
  validateFname,
  validateLname,
  validatePhone,
  validateCompany,
};
