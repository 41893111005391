import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../Button/Button';

import Arrow from '../../../icons/arrow.inline.svg';

import './AtAGlance.scss';

const renderBoxes = scrollToElements => box => (
  <div key={box.title} className="at-a-glance__box">
    <h3 className="at-a-glance__box-title">
      <span className="at-a-glance__box-icon">
        <img src={box.icon.publicURL} alt={box.title} />
      </span>
      {box.title}
    </h3>
    <div className="at-a-glance__box-content text-body-14">{box.content}</div>
    <div
      tabIndex="0"
      role="button"
      className="at-a-glance__box-link"
      onClick={scrollToElements[box.component]}
      onKeyPress={scrollToElements[box.component]}
    >
      <Arrow />
      Learn More
    </div>
  </div>
);

const AtAGlance = ({
  scrollToBookADemo,
  scrollToElements,
  IlustrationIcon,
}) => {
  const {
    ourProductJson: { atAGlance },
  } = useStaticQuery(graphql`
    {
      ourProductJson {
        atAGlance {
          content
          component
          title
          icon {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <div className="at-a-glance">
      <div className="container at-a-glance__container">
        <div className="at-a-glance__first-column">
          <h2>A platform dedicated to keeping you secure.</h2>
          <IlustrationIcon />
        </div>
        <div className="at-a-glance__second-column">
          <h4>Edwin at-a-glance</h4>
          <div className="at-a-glance__boxes-wrapper">
            {atAGlance.map(renderBoxes(scrollToElements))}
          </div>
          <div className="at-a-glance__bottom">
            <p>
              Help secure the people in your organization— from their work life
              and beyond.
            </p>
            <Button fluid={false} onClick={scrollToBookADemo} type="secondary">
              Book a Demo
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

AtAGlance.defaultProps = {
  IlustrationIcon: null,
};

AtAGlance.propTypes = {
  scrollToBookADemo: PropTypes.func.isRequired,
  IlustrationIcon: PropTypes.func,
  scrollToElements: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default AtAGlance;
