import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './CheckList.scss';

const renderSteps = step => (
  <li key={step.content}>
    {step.title && <p className="check-list__list-title">{step.title}</p>}
    <p
      className={classnames('check-list__list-content', {
        'check-list__list-content--no-title': !step.title,
      })}
    >
      {step.content}
    </p>
  </li>
);

const CheckList = ({ content }) => (
  <div className="check-list__box">
    <h2 className="check-list__title">
      <img src={content.icon.publicURL} alt={content.title} />
      {content.title}
    </h2>
    <p className="check-list__description">{content.description}</p>
    {content.checklistTitle && (
      <p className="check-list__subtitle">{content.checklistTitle}</p>
    )}
    <ul className="check-list__list">{content.elements.map(renderSteps)}</ul>
  </div>
);

CheckList.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    checklistTitle: PropTypes.string,
    icon: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
  }).isRequired,
};

export default CheckList;
