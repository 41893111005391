import { useRef } from 'react';

const useScroll = () => {
  const ref = useRef(null);

  const executeScroll = () => {
    try {
      window.scroll({
        top: ref.current.offsetTop,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, ref.current.offsetTop);
    }
  };

  const htmlElementAttributes = ref;

  return [executeScroll, htmlElementAttributes];
};

export default useScroll;
