import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './FeatureSection.scss';

const FeatureSection = ({
  isReversed,
  rowViewOnTablet,
  firstColumn,
  secondColumn,
  className,
}) => (
  <div className="container feature-section__container">
    <div
      className={classnames(
        'feature-section',
        { 'feature-section--reversed': isReversed },
        { 'feature-section--row-view': rowViewOnTablet },
        className,
      )}
    >
      <div className="feature-section__first-column">{firstColumn}</div>
      <div className="feature-section__second-column">{secondColumn}</div>
    </div>
  </div>
);

FeatureSection.defaultProps = {
  isReversed: false,
  rowViewOnTablet: false,
  firstColumn: undefined,
  secondColumn: undefined,
  className: '',
};

FeatureSection.propTypes = {
  isReversed: PropTypes.bool,
  rowViewOnTablet: PropTypes.bool,
  firstColumn: PropTypes.node,
  secondColumn: PropTypes.node,
  className: PropTypes.string,
};

export default FeatureSection;
