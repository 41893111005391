import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import SliderSection from '../../shared/SliderSection/SliderSection';

const ThirdHumanFeature = ({ scrollToBookADemo }) => {
  const {
    ourProductJson: { humanFeature },
  } = useStaticQuery(graphql`
    {
      ourProductJson {
        humanFeature {
          slider3 {
            id
            shapePath
            text
            image {
              src {
                id
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
              flair {
                top
                left
                rotate
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SliderSection
      type="left"
      sliderContent={[humanFeature.slider3]}
      button={{ onClick: scrollToBookADemo, text: 'Book a Demo' }}
    />
  );
};

ThirdHumanFeature.propTypes = {
  scrollToBookADemo: PropTypes.func.isRequired,
};

export default ThirdHumanFeature;
