import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Header from '../components/Header/Header';

import Introduction from '../components/shared/Introduction/Introduction';
import AtAGlance from '../components/shared/AtAGlance/AtAGlance';
import FirstHumanFeature from '../components/OurProduct/HumanFeature/FirstHumanFeature';
import SecondHumanFeature from '../components/OurProduct/HumanFeature/SecondHumanFeature';
import ThirdHumanFeature from '../components/OurProduct/HumanFeature/ThirdHumanFeature';
import FourthHumanFeature from '../components/OurProduct/HumanFeature/FourthHumanFeature';

import CheckListRightFirstGroup from '../components/OurProduct/CheckLists/CheckListRightFirstGroup';
import CheckListRightSecondGroup from '../components/OurProduct/CheckLists/CheckListRightSecondGroup';
import CheckListRightThirdGroup from '../components/OurProduct/CheckLists/CheckListRightThirdGroup';

import CheckListLeftFirstGroup from '../components/OurProduct/CheckLists/CheckListLeftFirstGroup';
import CheckListLeftSecondGroup from '../components/OurProduct/CheckLists/CheckListLeftSecondGroup';
import CheckListLeftThirdGroup from '../components/OurProduct/CheckLists/CheckListLeftThirdGroup';

import Customers from '../components/shared/Customers/Customers';
import BookADemo from '../components/shared/BookADemo/BookADemo';
import IlustrationIcon from '../icons/ilustration_05.inline.svg';

import useScroll from '../utils/useScroll';

const OurProduct = () => {
  const [scrollToBookADemo, bookADemoComponent] = useScroll();

  const [
    scrollToCheckListRightFirstGroup,
    refCheckListRightFirstGroup,
  ] = useScroll();
  const [
    scrollToCheckListRightSecondGroup,
    refCheckListRightSecondGroup,
  ] = useScroll();
  const [
    scrollToCheckListRightThirdGroup,
    refCheckListRightThirdGroup,
  ] = useScroll();

  const [
    scrollToCheckListLeftFirstGroup,
    refCheckListLeftFirstGroup,
  ] = useScroll();
  const [
    scrollToCheckListLeftSecondGroup,
    refCheckListLeftSecondGroup,
  ] = useScroll();
  const [
    scrollToCheckListLeftThirdGroup,
    refCheckListLeftThirdGroup,
  ] = useScroll();

  const scrollToElements = {
    'crafted-for-you': scrollToCheckListRightFirstGroup,
    'behavioral-security': scrollToCheckListLeftFirstGroup,
    'security-posture': scrollToCheckListRightSecondGroup,
    'latest-threats': scrollToCheckListLeftSecondGroup,
    'security-to-others': scrollToCheckListRightThirdGroup,
    'security-organization': scrollToCheckListLeftThirdGroup,
  };

  return (
    <Layout>
      <Header demoButtonNavigation="/book-demo" />
      <Seo
        title="Edwin - Cybersecurity designed for humans"
        description="Edwin is a behavioral cybersecurity platform that helps organizations reduce security risks, comply with policies, and shape their security culture through small, ongoing, and verifiable security missions."
      />
      <Introduction
        scrollToBookADemo={scrollToBookADemo}
        listElements={[
          'Small, actionable steps that everyone can do',
          'Practice that builds confidence and competence',
          'Programs customized to your unique security needs',
          'Designed for behavior change that enhances security',
        ]}
        title={
          <h1>
            <span className="text-main-color">Cybersecurity</span>
            <br /> built just for you.
          </h1>
        }
      />
      <AtAGlance
        scrollToBookADemo={scrollToBookADemo}
        scrollToElements={scrollToElements}
        IlustrationIcon={IlustrationIcon}
      />
      <FirstHumanFeature scrollToBookADemo={scrollToBookADemo} />
      <CheckListRightFirstGroup refToElement={refCheckListRightFirstGroup} />
      <CheckListLeftFirstGroup refToElement={refCheckListLeftFirstGroup} />
      <SecondHumanFeature scrollToBookADemo={scrollToBookADemo} />
      <CheckListLeftSecondGroup refToElement={refCheckListLeftSecondGroup} />
      <CheckListRightSecondGroup refToElement={refCheckListRightSecondGroup} />
      <ThirdHumanFeature scrollToBookADemo={scrollToBookADemo} />
      <CheckListRightThirdGroup refToElement={refCheckListRightThirdGroup} />
      <CheckListLeftThirdGroup refToElement={refCheckListLeftThirdGroup} />
      <FourthHumanFeature scrollToBookADemo={scrollToBookADemo} />
      <Customers />
      <BookADemo refToElement={bookADemoComponent} />
    </Layout>
  );
};

export default OurProduct;
