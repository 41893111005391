import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import HeaderSection from '../../HeaderSection/HeaderSection';
import DemoSection from '../DemoSection/DemoSection';
import BookADemoForm from '../BookADemoForm/BookADemoForm';

import './BookADemo.scss';

const BookADemo = ({ refToElement }) => {
  const [isBooked, book] = useState(false);
  const { thumbnail, image } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/01.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      image: file(relativePath: { regex: "/whatWeDo/slider/0/" }) {
        extension
        id
        publicURL
      }
    }
  `);

  const demoList = [
    {
      id: 0,
      thumbnail: {
        position: 'left',
        src: thumbnail,
      },
      shapePath:
        'M568.999 199.743C616.829 284.119 500.974 468.875 370.586 522.477C259.403 568.172 96.9317 524.894 30.6912 387.986C-79.8323 159.635 130.956 -99.7949 300.878 38.906C437.856 150.716 534.047 125.992 568.999 199.743Z',
      content: {
        src: image,
        type: 'box-padding',
      },
      type: 'box',
    },
  ];

  const bookMessages = {
    booked: {
      title: 'Thank You!',
      description: (
        <span>
          Together we will change the way security works by helping everyone in
          your organization become masters of cybersecurity behavior to be the
          most secure they possibly can be.
        </span>
      ),
    },
    not_booked: {
      title: 'Together let’s change the way security works.',
      description: (
        <span>
          {' '}
          We’re here to answer your questions and provide a demo of our personal
          security platform. Email{' '}
          <a href="mailto:sales@edwinsecure.com">sales@edwinsecure.com</a>, call
          us at (504) 475-0517, or fill out the form below.{' '}
        </span>
      ),
    },
  };

  useEffect(() => {
    book(localStorage.getItem('isDemoBooked') || false);
  }, []);

  const setDemoBooked = useCallback(() => {
    book(true);
    localStorage.setItem('isDemoBooked', true);
  }, []);

  const bookMessagesType = isBooked ? 'booked' : 'not_booked';
  return (
    <div
      className={classnames('book-a-demo', {
        'book-a-demo__booked': isBooked,
      })}
      ref={refToElement}
    >
      <div className="container book-a-demo__container">
        <div className="book-a-demo__first-column">
          <DemoSection demos={demoList} activeElement={0} />
        </div>
        <div className="book-a-demo__second-column">
          <HeaderSection
            subTitle="Book a demo"
            title={bookMessages[bookMessagesType].title}
            description={bookMessages[bookMessagesType].description}
          />
          {!isBooked && <BookADemoForm setDemoBooked={setDemoBooked} />}
        </div>
      </div>
    </div>
  );
};

BookADemo.defaultProps = {
  refToElement: null,
};

BookADemo.propTypes = {
  refToElement: PropTypes.shape({ current: PropTypes.any }),
};

export default BookADemo;
