import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import Button from '../Button/Button';
import DemoSection from '../DemoSection/DemoSection';

import './Introduction.scss';

const renderListElements = element => <li key={element}>{element}</li>;

const Introduction = ({
  scrollToBookADemo,
  title,
  listElements,
  isReversed,
}) => {
  const { thumbnail, image } = useStaticQuery(graphql`
    {
      thumbnail: file(relativePath: { eq: "rectangles/01.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      image: file(relativePath: { regex: "/whatWeDo/slider/0/" }) {
        extension
        id
        publicURL
      }
    }
  `);

  const demoList = [
    {
      id: 0,
      thumbnail: {
        position: 'left',
        src: thumbnail,
      },
      shapePath:
        'M185.361 70.5272C249.115 24.1162 315.672 -28.3917 416.059 18.1657C487.118 51.1214 518.109 102.95 514.362 168.834C510.616 234.718 516.58 235.663 559.926 290.193C603.271 344.723 579.09 475.894 461.208 473.677C343.325 471.46 192.499 634.242 48.7026 450.193C-60.4038 310.378 26.2643 186.345 185.361 70.5272Z',
      content: {
        src: image,
        type: 'box-padding',
      },
      type: 'box',
    },
  ];

  return (
    <div className="introduction">
      <div
        className={classnames('container introduction__container', {
          'introduction__container--is-reversed': isReversed,
        })}
      >
        <div className="introduction__first-column">
          {title}
          <ul className="introduction__elements-list">
            {listElements.map(renderListElements)}
          </ul>
          <Button
            fluid={false}
            onClick={scrollToBookADemo}
            type="secondary"
            className="introduction__button"
          >
            Book a Demo
          </Button>
        </div>
        <div className="introduction__second-column">
          <DemoSection demos={demoList} activeElement={0} />
        </div>
      </div>
    </div>
  );
};

Introduction.defaultProps = {
  isReversed: false,
  scrollToBookADemo: null,
};

Introduction.propTypes = {
  scrollToBookADemo: PropTypes.func,
  title: PropTypes.element.isRequired,
  listElements: PropTypes.arrayOf(PropTypes.string).isRequired,
  isReversed: PropTypes.bool,
};

export default Introduction;
