import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import DemoSection from '../../shared/DemoSection/DemoSection';
import FeatureSection from '../../shared/FeatureSection/FeatureSection';

import CheckList from '../../shared/CheckList/CheckList';

const CheckListRightSecondGroup = ({ refToElement }) => {
  const {
    ourProductJson: { checkList },
    thumbnail,
    image,
  } = useStaticQuery(graphql`
    {
      ourProductJson {
        checkList {
          section4 {
            description
            id
            title
            component
            icon {
              publicURL
            }
            elements {
              content
              title
            }
          }
        }
      }
      thumbnail: file(relativePath: { eq: "rectangles/02.png" }) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      image: file(relativePath: { regex: "/howItWorks/slider/3/" }) {
        extension
        id
        publicURL
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  `);

  const demoList = [
    {
      id: 0,
      thumbnail: {
        position: 'right',
        src: thumbnail,
      },
      shapePath:
        'M568.999 199.743C616.829 284.119 500.974 468.875 370.586 522.477C259.403 568.172 96.9317 524.894 30.6912 387.986C-79.8323 159.635 130.956 -99.7949 300.878 38.906C437.856 150.716 534.047 125.992 568.999 199.743Z',
      content: {
        src: image,
        type: 'box',
      },
    },
  ];

  return (
    <div className="check-list" ref={refToElement}>
      <div id={checkList.section4.component} />
      <FeatureSection
        isReversed
        firstColumn={<DemoSection demos={demoList} activeElement={0} />}
        secondColumn={<CheckList content={checkList.section4} />}
      />
    </div>
  );
};

CheckListRightSecondGroup.propTypes = {
  refToElement: PropTypes.shape({ current: PropTypes.any }).isRequired,
};

export default CheckListRightSecondGroup;
