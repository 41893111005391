import React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import SliderSection from '../../shared/SliderSection/SliderSection';

const FourthHumanFeature = ({ scrollToBookADemo }) => {
  const {
    ourProductJson: { humanFeature },
  } = useStaticQuery(graphql`
    {
      ourProductJson {
        humanFeature {
          slider4 {
            id
            shapePath
            text
            image {
              src {
                id
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
              flair {
                top
                left
                rotate
              }
            }
          }
        }
      }
    }
  `);

  return (
    <SliderSection
      type="right"
      sliderContent={[humanFeature.slider4]}
      button={{ onClick: scrollToBookADemo, text: 'Book a Demo' }}
    />
  );
};

FourthHumanFeature.propTypes = {
  scrollToBookADemo: PropTypes.func.isRequired,
};

export default FourthHumanFeature;
