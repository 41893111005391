import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import axios from 'axios';
import { Formik, Form, Field } from 'formik';

import {
  validateEmail,
  validateFname,
  validateLname,
  validatePhone,
  validateCompany,
} from '../../../utils/formValidationRules';

import './BookADemoForm.scss';

const BookADemoForm = ({ setDemoBooked }) => {
  const bookADemo = values => {
    const data = {
      first_name: values.fname,
      last_name: values.lname,
      email: values.email,
      phone_number: values.phone,
      company_name: values.company,
    };

    axios
      .post(
        `https://exec.clay.run/zbigniew/insert-record-into-book-a-demo-in-edwin-crm-airtable?authToken=tok_2c8a4998bb7c29847e0021b6e`, // TODO: Add URL to config
        { ...data },
      )
      .then(() => {
        setDemoBooked(true);
      })
      .catch(error => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  return (
    <div className="book-a-demo-form">
      <Formik
        initialValues={{
          fname: '',
          lname: '',
          email: '',
          phone: '',
          company: '',
        }}
        onSubmit={values => {
          bookADemo(values);
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="book-a-demo-form__form">
            {errors.fname && touched.fname && (
              <p className="text-body-14 book-a-demo-form__error-message">
                {errors.fname}
              </p>
            )}
            <Field
              className={classnames('book-a-demo-form__input', {
                'book-a-demo-form__input--error': errors.fname && touched.fname,
                'book-a-demo-form__input--isValid':
                  !errors.fname && touched.fname,
              })}
              name="fname"
              placeholder="First Name"
              validate={validateFname}
            />

            {errors.lname && touched.lname && (
              <p className="text-body-14 book-a-demo-form__error-message">
                {errors.lname}
              </p>
            )}
            <Field
              className={classnames('book-a-demo-form__input', {
                'book-a-demo-form__input--error': errors.lname && touched.lname,
                'book-a-demo-form__input--isValid':
                  !errors.lname && touched.lname,
              })}
              name="lname"
              placeholder="Last Name"
              validate={validateLname}
            />

            {errors.phone && touched.phone && (
              <p className="text-body-14 book-a-demo-form__error-message">
                {errors.phone}
              </p>
            )}
            <Field
              className={classnames('book-a-demo-form__input', {
                'book-a-demo-form__input--error': errors.phone && touched.phone,
                'book-a-demo-form__input--isValid':
                  !errors.phone && touched.phone,
              })}
              name="phone"
              placeholder="Phone Number"
              validate={validatePhone}
            />

            {errors.email && touched.email && (
              <p className="text-body-14 book-a-demo-form__error-message">
                {errors.email}
              </p>
            )}
            <Field
              className={classnames('book-a-demo-form__input', {
                'book-a-demo-form__input--error': errors.email && touched.email,
                'book-a-demo-form__input--isValid':
                  !errors.email && touched.email,
              })}
              name="email"
              placeholder="Email"
              validate={validateEmail}
            />

            {errors.company && touched.company && (
              <p className="text-body-14 book-a-demo-form__error-message">
                {errors.company}
              </p>
            )}
            <Field
              className={classnames('book-a-demo-form__input', {
                'book-a-demo-form__input--error':
                  errors.company && touched.company,
                'book-a-demo-form__input--isValid':
                  !errors.company && touched.company,
              })}
              name="company"
              placeholder="Company"
              validate={validateCompany}
            />
            <button
              type="submit"
              className="book-a-demo-form__button"
              disabled={isSubmitting}
            >
              Book a Demo
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

BookADemoForm.propTypes = {
  setDemoBooked: PropTypes.func.isRequired,
};

export default BookADemoForm;
